import React from "react"
import logo from './images/logo_white.png';
import 'font-awesome/css/font-awesome.min.css';


const Footer = () =>

<footer class="footer">
    
 
  <div class="footer-center col-md-4 col-sm-6">
      
    
    <div>
      <i class="fa fa-map-marker"></i>
      <p><span> Hollenaarstraat 42</span>9042 Oostakker</p>
    </div>
    <div>
      <i class="fa fa-phone"></i>
      <p>Tel: 0487/26.40.19</p>
    </div>
    <div>
      <i class="fa fa-envelope"></i>
      <p>  info@Coiffeur-soiree.com</p>
    </div>
  </div>
  <div class="footer-left col-md-4 col-sm-6">
   
   <div class="icons text-center">
     <a href="https://www.facebook.com/coiffeursoiree" rel="noreferrer" target="_blank"><i class="fa fa-facebook"></i></a>  
     <a href="https://www.instagram.com/coiffeursoiree_curlspecialist/" rel="noreferrer" target="_blank"><i class="fa fa-instagram"></i></a>
   </div>
 </div>
  <div class="footer-right col-md-4 col-sm-6">
    <h2> Coiffeur Soirée<span>   <img className="mt-3" src={logo}  alt="logo" width={100} />
      </span></h2>
    <p class="menu">
      <a href="/"> Home</a> |
      <a href="#overmij"> Over Mij</a> |
      <a href="#prijslijst"> Prijslijst</a> |
      <a href="#contact"> Contact</a>|
      <a href="https://salonkee.be/salon/coiffeur-soiree?lang=nl&modal=true" rel="noreferrer" target="_blank"> Book here</a> |
  
    </p>
    <p class="text-white"> Coiffeur Soirée &copy; 2016-2025</p>
    <p class="text-white"> BE0795284984 </p>

  </div>
</footer>

export default Footer